<template>
  <div v-frag>
    <v-data-table
      v-model='selectedEmployees'
      :headers='tableHeaders'
      :items='visibleItems'
      :loading='isTableLoading'
      :options.sync='tableOptions'
      :server-items-length='tableTotalItems'
      :items-per-page='10'
      checkboxColor='primary'
      item-key='id'
      loading-text='Loading employees... Please wait'
      no-data-text='There are no active employees at the moment.'
      show-select
      must-sort
    >
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title class='font-weight-bold'>{{ sections.employees || 'Employees title' }}</v-toolbar-title>
          <v-divider
            class='mx-4'
            inset
            vertical
          ></v-divider>
          <v-toolbar-title class='font-weight-bold text--primary'>
            {{ loggedEmployeesPercent }}% Logged In
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model='tableSearch'
            class='mr-4'
            dense
            hide-details
            label='Search'
            outlined
            prepend-inner-icon='mdi-magnify'
            single-line
            @input='searchEmployees'
          ></v-text-field>
          <v-btn
            class='text-capitalize font-weight-bold py-5'
            color='primary'
            outlined
            @click='isCreateDialogOpen = true'
          >
            <v-icon left> mdi-plus</v-icon>
            Add New
          </v-btn>
          <add-file-dialog
            download-button-name='Download Xlsx Template'
            download-link='excel/layoutAdmin.xlsx'
            title='Import Employees'
          />
          <add-file-dialog
            download-button-name='Download Current Employees'
            download-link='api/employee-exports'
            title='Update Employees'
          />
        </v-toolbar>
        <v-row
          v-if='selectedEmployees.length'
          class='d-flex justify-start align-center ml-4 my-4'
        >
          <v-btn
            class='warning--text mr-2'
            medium
            outlined
            @click='openSuspendDialog(selectedEmployees)'
          >
            <v-icon left>mdi-timer-off</v-icon>
            Suspend
          </v-btn>
          <v-btn
            :loading='areInvitesSending'
            class='mr-2 text-capitalize font-weight-bold'
            color='primary'
            outlined
            @click='sendMultipleInvites'
          >
            Send Selected Invites
          </v-btn>
          <v-btn
            :loading='areSelectedRescreening'
            class='mr-2 text-capitalize font-weight-bold'
            color='success'
            outlined
            @click='reScreenMultipleEmployees(selectedEmployees.map(el => el.id))'
          >
            <v-icon class='mr-2'>mdi-update</v-icon>

            Rescreen Selected
          </v-btn>
          <v-btn
            :loading='isTableLoading'
            class='text-capitalize font-weight-bold'
            color='cyan'
            outlined
            @click='handleSendWelcomeEmail(selectedEmployees.map(el => el.id))'
          >
            <v-icon class='mr-2'>mdi-update</v-icon>
            Send Welcome Email
          </v-btn>
        </v-row>
        <v-alert
          v-model='showSuspendAlert'
          class='rounded-xl'
          color='warning'
          dark
          dense
          dismissible
          icon='mdi-information-outline'
          prominent
          transition='scale-transition'
          width='100%'
        >
          <h3 class='mt-2'>Employee Suspended</h3>
          <p>You can always incorporate them back going to <b>"Suspended Employees"</b> at the bottom of this page</p>
        </v-alert>
      </template>
      <!--        <template #item.id={item}>-->
      <!--          <input type="checkbox" v-model="selectedEmployees" :value="item.id">-->
      <!--        </template>-->
      <template #item.fullName={item}>
              <span
                class='text-decoration-underline showPointer'
                @click='onEmployeeClick(item)'
              >
                {{ item.fullName }}
              </span>
      </template>
      <template #item.ineligible={item}>
        <i v-if="!item.ineligible" style="font-size: 20px" class="w-2 mdi-checkbox-marked green--text mdi"></i>
        <i v-else style="font-size: 20px" class="mdi mdi-close-circle red--text"></i>
      </template>
      <template #item.lastLoginDate={item}>
        <div v-if="item.lastLoginDate">
              <span>{{ item.lastLoginDate.split(' ')[0] }}<br>
                {{ item.lastLoginDate.split(' ')[1] }}</span>
        </div>
        <button
          v-else-if="item.invited"
          :disabled="isInviteSending && item.id === changeableEmployeeId"
          class='teal lighten-2 white--text rounded-sm v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--small'
          @click="resendOneInvite(item.id)"
        >
          Resend Invite
        </button>
        <button
          v-else
          :disabled="isInviteSending && item.id === changeableEmployeeId"
          @click="sendOneInvite(item.id)"
          class='teal lighten-2 white--text rounded-sm v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--small'
        >
          Send Invite
        </button>
      </template>

      <template #item.welcomeEmailSent={item}>
        <div v-if="item.isWelcomeEmailSent">
          <v-tooltip bottom>
            <template v-slot:activator='{ on }'>
              <span v-on='on'>Welcome Email Sent</span>
            </template>
            <span><b>First: </b>{{ item.welcomeEmail1SentAt }}</span><br>
            <span><b>Second: </b>{{ item.welcomeEmail2SentAt }}</span>
          </v-tooltip>
        </div>
        <button
          class="cyan white--text rounded-sm v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--small"
          v-else
          :disabled="isTableLoading"
          @click="handleSendWelcomeEmail([item.id])"
        >
          Send Welcome Email
        </button>
      </template>


      <template #item.actions={item}>
        <div class="d-flex flex-row flex-wrap">
          <button @click="openEditDialog(item.id)" class="pa-2 ma-1 primary white--text rounded-sm d-flex justify-center align-center">
            <i style="line-height:20px; font-size: 20px" class="mdi mdi-pencil"></i></button>
          <button @click="openSuspendDialog([item])" class="pa-2 ma-1 warning white--text rounded-sm d-flex justify-center align-center"><i
            style="line-height:20px; font-size: 20px" class="mdi mdi-timer-off"></i></button>
          <button @click="reScreenOneEmployee(item.id)" class="pa-2 ma-1 success white--text rounded-sm d-flex justify-center align-center">
            <img v-if="isSelectedRescreening && item.id === changeableEmployeeId" width="20px" height="20px" :src="loader" alt="loader">
            <i v-else style="line-height:20px; font-size: 20px" class="mdi mdi-update"></i>
          </button>
        </div>
      </template>
    </v-data-table>
    <CreateEmployeeDialog
      :is-open='isCreateDialogOpen'
      :is-edit='employeeEdit'
      :selected-employee='selectedEmployee'
      :is-creating='isEmployeeCreating'
      :is-updating-without-rescreen='isEmployeeUpdatingWithoutRescreen'
      :is-updating-with-rescreen='isEmployeeUpdatingWithRescreen'
      @create='createNewEmployee'
      @update-without-rescreen='updateEmployeeWithoutRescreen'
      @update-with-rescreen='updateEmployeeWithRescreen'
      @go-to-search='isSearchDialogOpen = true'
      @close='closeEmployeeDialog'
    />
    <SearchEmployeeDialog
      :is-open='isSearchDialogOpen'
      @found='getFoundEmployee'
      @close='isSearchDialogOpen = false'
    />
    <SuspendItem
      :items='itemsToSuspend'
      :is-open='isSuspendDialogOpen'
      title='Suspend Employee'
      subtitle='employee'
      @submit='suspendEmployees'
      @close='isSuspendDialogOpen = false'
    />
    <RescreenDialog
      v-if='isRescreenDialogOpen'
      :is-open='isRescreenDialogOpen'
      :data='rescreenData'
      @close-dialog='closeRescreenDialog'
    />
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import CreateEmployeeDialog from '@/views/employee/components/CreateEmployeeDialog.vue'
import SearchEmployeeDialog from '@/views/employee/components/SearchEmployeeDialog.vue'
import AddFileDialog from '@/components/dialogs/add-file-dialog.vue'
import SuspendItem from '@/components/dialogs/SuspendItem.vue'
import RescreenDialog from '@/components/RescreenDialog.vue'
import loader from "@/assets/images/loader.png";

export default {
  name: 'ActiveEmployeesTable',
  components: {RescreenDialog, SuspendItem, AddFileDialog, CreateEmployeeDialog, SearchEmployeeDialog},
  data() {
    return {
      loader: loader,
      employees: [],
      visibleItems: [],
      selectedEmployees: [],
      tableSearch: '',
      isTableLoading: false,
      tableOptions: {},
      tableTotalItems: 0,
      tableHeaders: [
        {text: 'Name', align: 'start', value: 'fullName'},
        {text: 'Eligibility', align: 'center', value: 'ineligible', sortable: false},
        {text: 'Company', value: 'company'},
        {text: 'County', value: 'county.text', sortable: false},
        {text: 'Hierarchy', value: 'hierarchyDescription'},
        {text: 'Welcome Email', align: 'center', value: 'welcomeEmailSent'},
        {text: 'VUID', value: 'vuid'},
        {text: 'Last Login Time', value: 'lastLoginDate', align: 'center', sortable: false},
        {text: 'Actions', value: 'actions', sortable: false}
      ],
      isCreateDialogOpen: false,
      employeeEdit: false,
      isEmployeeCreating: false,
      isEmployeeUpdatingWithoutRescreen: false,
      isEmployeeUpdatingWithRescreen: false,
      isSearchDialogOpen: false,
      isSuspendDialogOpen: false,
      itemsToSuspend: [],
      selectedEmployee: {},
      areInvitesSending: false,
      isInviteSending: false,
      areSelectedRescreening: false,
      isSelectedRescreening: false,
      rescreenData: [],
      isRescreenDialogOpen: false,
      isEditLoading: false,
      changeableEmployeeId: null,
      showSuccessAlert: false,
      showSuspendAlert: false,
      setTimeoutTimer: null
    }
  },
  computed: {
    ...mapState({
      sections: s => s.cmsStore.sections
    }),
    loggedEmployeesPercent() {
      return Math.round(this.employees.filter(el => el.lastLoginDate).length / this.employees.length * 100) || '0'
    }
  },
  watch: {
    tableOptions: {
      async handler() {
        await this.getEmployees()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      getAllEmployees: 'employeeStore/getAllEmployees',
      createEmployee: 'employeeStore/createEmployee',
      getEmployeeInfoToEdit: 'employeeStore/getEmployeeInfoToEdit',
      editEmployeeByAdminWithoutRescreen: 'employeeStore/editEmployeeByAdminWithoutRescreen',
      editEmployeeWithRescreen: 'employeeStore/editEmployeeByAdmin',
      suspend: 'employeeStore/enableDisableMultipleEmployees',
      sendVerificationEmail: 'userStore/sendVerificationEmail',
      resendVerificationEmail: 'userStore/resendVerificationEmail',
      reScreenEmployees: 'employeeStore/reScreenEmployees',
      setEmployee: 'employee',
      sendWelcomeEmail: 'employeeStore/sendWelcomeEmail'
    }),
    onEmployeeClick(item) {
      this.$store.dispatch('employee', item)
      this.$router.push(`/employee/profile/${ item.id }`)
    },
    async getEmployees() {
      this.employees = []
      this.visibleItems = []
      this.isTableLoading = true
      const {itemsPerPage, page, sortBy, sortDesc} = this.tableOptions
      const params = {
        page,
        per_page: itemsPerPage > 0 ? itemsPerPage : 1000,
        search: this.tableSearch || null,
        sort_by: sortBy ? sortBy[0] : null,
        order: sortBy ? (sortDesc[0] ? 'desc' : 'asc') : null
      }
      const {data: {data, meta: {total}}} = await this.getAllEmployees(params)
      this.tableTotalItems = total
      this.employees = data
      this.visibleItems = this.employees
      // this.visibleItems = this.employees.slice(0, 50)
      this.isTableLoading = false
    },
    async createNewEmployee(data) {
      this.isEmployeeCreating = true
      const dataToSend = this.createEmployeeDataToSend(data)
      await this.createEmployee(dataToSend)
      this.isEmployeeCreating = false
      this.closeEmployeeDialog()
      await this.getEmployees()
      // this.showAlertMsg('success')
    },
    async updateEmployeeWithoutRescreen(data) {
      this.isEmployeeUpdatingWithoutRescreen = true
      const dataToSend = this.createEmployeeDataToSend(data)
      await this.editEmployeeByAdminWithoutRescreen({
        id: data.id,
        rescreen: data.rescreen,
        ...dataToSend,
        eligibilityDetailId: data.inelegible ? data.eligibilityDetailId : null
      })
      this.isEmployeeUpdatingWithoutRescreen = false
      this.closeEmployeeDialog()
      this.employeeEdit = false
      await this.getEmployees()
      // this.showAlertMsg('success')
    },
    async updateEmployeeWithRescreen(data) {
      this.isEmployeeUpdatingWithRescreen = true
      const dataToSend = this.createEmployeeDataToSend(data)
      const res = await this.editEmployeeWithRescreen({
        id: data.id,
        rescreen: data.rescreen,
        ...dataToSend,
        eligibilityDetailId: data.inelegible ? data.eligibilityDetailId : null
      })
      this.isEmployeeUpdatingWithRescreen = false
      this.closeEmployeeDialog()
      this.employeeEdit = false
      if (res.data.length) {
        this.rescreenData = res.data
        this.isRescreenDialogOpen = true
      } else {
        await this.$store.dispatch('snackbar', {
          show: true,
          color: 'success',
          message: res.message[0]
        })
      }
      await this.getEmployees()
    },
    createEmployeeDataToSend(data) {
      return {
        firstName: data.firstName,
        voter_county_id: data.voterCounty,
        secondName: data.secondName,
        lastName: data.lastName,
        userName: data.userName,
        email: data.email,
        secondaryEmail: data.secondaryEmail,
        homePhone: data.homePhone,
        workPhone: data.workPhone,
        mobilePhone: data.mobilePhone,
        mailAddress: data.mailAddress,
        mailCity: data.mailCity,
        mailState: data.mailState,
        mailZip: data.mailZip,
        voterAddress: data.voterAddress,
        voterCity: data.voterCity,
        voterState: data.voterState,
        voterZip: data.voterZip,
        county_id: data.county,
        vuid: data.vuid,
        birthDate: data.birthDate,
        employeeRoleId: data.hierarchy,
        reportsTo: data.reportsTo,
        member_id: data.memberId,
        inelegible: data.inelegible,
        location: data.location,
        department: data.department,
        eligibility_detail_id: data.inelegible ? data.eligibilityDetailId : null
      }
    },
    async openEditDialog(employeeId) {
      this.employeeEdit = true
      this.changeableEmployeeId = employeeId
      this.isEditLoading = true
      const res = await this.getEmployeeInfoToEdit(employeeId)
      this.selectedEmployee = {
        id: res.id,
        firstName: res.firstName,
        secondName: res.secondName,
        lastName: res.lastName,
        birthDate: res.birthDate,
        homePhone: res.homePhone,
        workPhone: res.workPhone,
        mobilePhone: res.mobilePhone,
        county: res.county.value ? res.county.value : null,
        mailAddress: res.mailAddress,
        mailCity: res.mailCity,
        mailState: res.mailState,
        mailZip: res.mailZip,
        voterAddress: res.voterAddress,
        voterCity: res.voterCity,
        voterState: res.voterState,
        voterZip: res.voterZip,
        voterCounty: res.voterCounty.value ? res.voterCounty.value : null,
        vuid: res.vuid,
        department: res.department,
        location: res.location,
        email: res.email,
        secondaryEmail: res.secondaryEmail,
        memberId: res.memberId,
        reportsTo: res.reportsTo.id,
        hierarchy: res.hierarchy.id,
        inelegible: res.inelegible,
        eligibilityDetailId: res.inelegible ? res.eligibilityDetailId : null
      }
      this.isEditLoading = false

      this.isCreateDialogOpen = true
    },
    closeEmployeeDialog() {
      this.isCreateDialogOpen = false
      this.employeeEdit = false
      this.selectedEmployee = {}
    },
    searchEmployees() {
      clearTimeout(this.setTimeoutTimer)
      this.setTimeoutTimer = setTimeout(() => this.tableOptions = {...this.tableOptions, page: 1}, 500)
    },
    openSuspendDialog(items) {
      this.itemsToSuspend = items
      this.isSuspendDialogOpen = true
    },
    async suspendEmployees() {
      await this.suspend({employees: this.itemsToSuspend})
      this.selectedEmployees = []
      this.isSuspendDialogOpen = false
      this.showAlertMsg('suspend')
      await this.getEmployees()
    },
    async sendMultipleInvites() {
      this.areInvitesSending = true
      await this.sendVerificationEmail(this.selectedEmployees.map(el => el.id))
      this.areInvitesSending = false
      await this.getEmployees()
    },
    async sendOneInvite(employeeId) {
      this.changeableEmployeeId = employeeId
      this.isInviteSending = true
      await this.sendVerificationEmail([employeeId])
      this.isInviteSending = false
      await this.getEmployees()
    },
    async resendOneInvite(employeeId) {
      this.changeableEmployeeId = employeeId
      this.isInviteSending = true
      await this.resendVerificationEmail(employeeId)
      this.isInviteSending = false
      await this.getEmployees()
    },
    async reScreenMultipleEmployees(employeesIds) {
      this.areSelectedRescreening = true
      const res = await this.reScreenEmployees(employeesIds)
      this.checkRescreen(res)
      this.areSelectedRescreening = false
      await this.getEmployees()
    },
    async reScreenOneEmployee(employeeId) {
      this.changeableEmployeeId = employeeId
      this.isSelectedRescreening = true
      const res = await this.reScreenEmployees([employeeId])
      this.checkRescreen(res)
      this.isSelectedRescreening = false
      await this.getEmployees()
    },
    checkRescreen(result) {
      if (result.data.length) {
        this.rescreenData = result.data
        this.isRescreenDialogOpen = true
      }
    },
    async closeRescreenDialog() {
      this.isRescreenDialogOpen = false
      await this.getEmployees()
    },
    getFoundEmployee(value) {
      this.selectedEmployee = {
        firstName: value.first_name,
        secondName: value.middle_name,
        lastName: value.last_name,
        vuid: value.vuid,
        mailAddress: value.mailAddress,
        mailCity: value.mailCity,
        mailZip: value.mailZip,
        mailState: 'TX',
        voterAddress: value.voterAddress,
        voterCity: value.voterCity,
        voterZip: value.voterZip,
        voterState: 'TX',
        birthDate: value.birth_day,
        county: value.county?.id,
        voterCounty: value.voterCounty?.id
      }
      this.isSearchDialogOpen = false
    },
    // goToEmployee(employee) {
    //   this.setEmployee(employee)
    //   this.$router.push(`/employee/profile/${ employee.id }`)
    // },
    // onTableScroll() {
    //   const container = this.$refs.tableRef
    //   if (container.scrollTop + container.clientHeight >= container.scrollHeight - 200) {
    //     const startIndex = this.visibleItems.length
    //     const endIndex = startIndex + 50
    //     this.visibleItems = this.visibleItems.concat(this.employees.slice(startIndex, endIndex))
    //   }
    // },
    showAlertMsg(type) {
      switch (type) {
        case 'success':
          this.showSuccessAlert = true
          break
        case 'suspend':
          this.showSuspendAlert = true
          break
      }
      this.hideAlertMsg()
    },
    hideAlertMsg() {
      window.setTimeout(() => {
        this.showSuccessAlert = false
        this.showSuspendAlert = false
      }, 5000)
    },
    async handleSendWelcomeEmail(employeeIds) {
      this.changeableEmployeeId = employeeIds[0];
      this.isTableLoading = true;
      await this.sendWelcomeEmail({employee_ids: employeeIds});
      await this.getEmployees();
      this.isTableLoading = false;
      this.changeableEmployeeId = null;
    }
  }
}
</script>

<style lang='scss' scoped>
.table-wrapper {
  max-height: 80vh;
  height: 100%;
  overflow-y: auto;

  ::v-deep .v-data-table__wrapper {
    padding-bottom: 80px;
  }

  ::v-deep .v-data-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
  }
}

.cursor-pointer {
  cursor: pointer;
}
</style>
